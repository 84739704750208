<meta http-equiv="X-UA-Compatible" content="IE=edge" />

<div class="container" *ngIf="isloggedIn">
  <mat-sidenav-container class="sidenav-container mat-elevation-z8">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false">
      <mat-toolbar>
       <span class="brand"> <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;width: 50px;" xml:space="preserve">
     <style type="text/css">
       .st0{fill:#FFFFFF;}
       .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
     </style>
     <g>
       <path class="st0" d="M16.25,42.81c0.41,1.75,1.53,2.63,3.36,2.63c0.9,0,1.61-0.2,2.12-0.6c0.52-0.4,0.78-0.97,0.78-1.71
         c0-0.64-0.23-1.12-0.68-1.44c-0.44-0.32-1.35-0.65-2.72-0.99c-2.37-0.6-3.99-1.34-4.88-2.23c-0.89-0.89-1.33-1.99-1.33-3.32
         c0-1.59,0.64-2.85,1.92-3.78c1.28-0.94,2.9-1.42,4.84-1.42c1.89,0,3.41,0.46,4.55,1.37c1.14,0.9,1.82,2.23,2.03,3.98h-3.83
         c-0.34-1.31-1.25-1.97-2.75-1.97c-0.83,0-1.5,0.16-2.02,0.47c-0.51,0.31-0.76,0.76-0.76,1.36c0,0.5,0.21,0.92,0.63,1.24
         c0.43,0.32,1.29,0.63,2.59,0.92c2.65,0.61,4.38,1.37,5.18,2.29c0.81,0.92,1.21,2.09,1.21,3.51c0,1.7-0.63,3.09-1.9,4.15
         c-1.27,1.07-2.92,1.6-4.97,1.6c-4.18,0-6.58-2.02-7.2-6.06H16.25z"/>
       <path class="st0" d="M28,48.49V30.31h5.61c3.14,0,5.53,0.79,7.19,2.38c1.65,1.58,2.48,3.82,2.48,6.72c0,2.91-0.83,5.15-2.49,6.73
         c-1.65,1.57-4.04,2.35-7.17,2.35H28z M31.95,33.72v11.36h1.66c3.79,0,5.68-1.89,5.68-5.68c0-3.79-1.89-5.68-5.68-5.68H31.95z"/>
       <path class="st0" d="M47.62,42.81c0.41,1.75,1.53,2.63,3.36,2.63c0.9,0,1.61-0.2,2.12-0.6c0.52-0.4,0.78-0.97,0.78-1.71
         c0-0.64-0.23-1.12-0.68-1.44c-0.44-0.32-1.35-0.65-2.72-0.99c-2.37-0.6-3.99-1.34-4.88-2.23c-0.89-0.89-1.33-1.99-1.33-3.32
         c0-1.59,0.64-2.85,1.92-3.78c1.28-0.94,2.9-1.42,4.84-1.42c1.89,0,3.41,0.46,4.55,1.37c1.14,0.9,1.82,2.23,2.03,3.98h-3.83
         c-0.34-1.31-1.25-1.97-2.75-1.97c-0.83,0-1.5,0.16-2.02,0.47c-0.51,0.31-0.76,0.76-0.76,1.36c0,0.5,0.21,0.92,0.63,1.24
         c0.43,0.32,1.29,0.63,2.59,0.92c2.65,0.61,4.38,1.37,5.18,2.29c0.81,0.92,1.21,2.09,1.21,3.51c0,1.7-0.63,3.09-1.9,4.15
         c-1.27,1.07-2.92,1.6-4.97,1.6c-4.18,0-6.58-2.02-7.2-6.06H47.62z"/>
       <path class="st1" d="M9.99,43.59c-0.32,0-0.6,0-0.81,0c-1.06,0-2.15-0.57-3.09-1.79c-0.94-1.22-1.61-2.95-1.77-4.82
         c-0.16-1.86,0.2-3.74,1.16-5.31c0.94-1.54,2.53-2.87,5.05-3.58l1.41-0.4l-0.13-1.43c-1.08-12.15,15.49-17.37,21.76-5.3l0.81,1.56
         l1.6-0.79c2.67-1.32,4.75-0.74,6.24,0.43c1.64,1.29,2.47,3.21,2.47,4.25v1.75h1.79c0.1,0,0.2,0,0.3,0.01
         c1.31-0.52,2.74-0.75,4.24-0.75c2.29,0,4.41,0.56,6.12,1.92c0.54,0.43,1.01,0.92,1.4,1.45c-0.31-0.69-0.71-1.37-1.22-2.01
         c-1.81-2.28-4.78-3.78-9.29-4.06c-0.51-1.88-1.79-3.88-3.57-5.28c-2.16-1.69-5.13-2.57-8.51-1.47c-8.3-13.12-27.88-7.38-27.78,7.22
         c-2.64,1.03-4.54,2.69-5.77,4.69c-1.41,2.3-1.87,4.94-1.66,7.39c0.21,2.45,1.08,4.83,2.48,6.63c1.4,1.81,3.45,3.18,5.96,3.18
         c0.45,0,1.15,0,2.04-0.01C10.62,46.03,10.22,44.85,9.99,43.59z"/>
     </g>
     </svg>&nbsp;&nbsp;- {{getCurrentpage()}} {{fnismenuEnable()}}</span> <span><button mat-button (click)="drawer.toggle()"><mat-icon>clear</mat-icon></button></span></mat-toolbar>

      <ul>
        <li class="nav-item" routerLinkActive="active" (mouseover)="OrganizationsidemenuTrigger.openMenu()" (mouseout)="$event.stopPropagation();OrganizationsidemenuTrigger.openMenu()">
          <mat-icon class="mat-icon-menu">account_balance</mat-icon>
          <a mat-button class="nav-link" #OrganizationsidemenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Organizationside" *ngIf="IsOrganizationMenu">Organization<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">play_arrow</mat-icon></a>
          <mat-menu #Organizationside="matMenu" [overlapTrigger]="false" xPosition="after" class="drop-spacer">
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('corporatebrand',$event)">Corporate Brand</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('productbrand',$event)">Product Brand</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('customergroup',$event)">Customer Group</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('division',$event)">Division</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('section',$event)">Section</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('department',$event)">Department</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('indexgroup',$event)">Index Group</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('index',$event)">Index</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('subindex',$event)">Sub Index</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('garmentgroup',$event)">Garment Group</button>
            <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('markupgroup',$event)">Markup Group</button>
            <button mat-menu-item class="matitem" (click)="openNewWindow('earlierplanning',$event)">Earlier Planning</button>
            <button mat-menu-item class="matitem" (click)="openNewWindow('southernhemisphere',$event)">Southern Hemisphere</button>
            <!-- <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('ab2department',$event)">Assortment Build</button> -->
            <button mat-menu-item class="matitem" (click)="openExternalUrl()">
              Data Hive
            </button>
            <mat-divider></mat-divider>
            <mat-divider></mat-divider>
            <mat-divider></mat-divider>
          </mat-menu>
        </li>
        <li  *ngIf="menuDisableEnv" class="nav-item" routerLinkActive="active" (mouseover)="MarketsidemenuTrigger.openMenu()" (mouseout)="$event.stopPropagation();MarketsidemenuTrigger.openMenu()">
          <mat-icon class="mat-icon-menu">account_balance</mat-icon>
          <a mat-button class="nav-link" #MarketsidemenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Marketside"  *ngIf="IsMarketMenu">Market  <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">play_arrow</mat-icon></a>
          <mat-menu #Marketside="matMenu" [overlapTrigger]="false" xPosition="after" class="drop-spacer">
            <button mat-menu-item class="horzdropitem" (click)="openNewWindow('channel',$event)">Channel</button>
          </mat-menu>
        </li>
        <li  *ngIf="menuDisableEnv" class="nav-item" routerLinkActive="active" (mouseover)="CountryidemenuTrigger.openMenu()" (mouseout)="$event.stopPropagation();CountryidemenuTrigger.openMenu()">
          <mat-icon class="mat-icon-menu">account_balance</mat-icon>
          <a mat-button class="nav-link" #CountryidemenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Countryside" *ngIf="IsCountryMenu">Country  <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">play_arrow</mat-icon></a>
          <mat-menu #Countryside="matMenu" [overlapTrigger]="false" xPosition="after" class="drop-spacer">
            <button mat-menu-item class="horzdropitem" (click)="openNewWindow('geographicalcountry',$event)">Geographical Country</button>
            <button mat-menu-item class="horzdropitem" (click)="openNewWindow('fiscalcountry',$event)">Fiscal Country</button>
            <button mat-menu-item class="horzdropitem" (click)="openNewWindow('hmcountry',$event)">H&M Country</button>
          </mat-menu>
        </li>
        
        <li *ngIf="menuDisableEnv"><mat-icon class="mat-icon-menu">list</mat-icon><a mat-button *ngIf="IsCustomMenu">Custom</a></li>
        <li *ngIf="menuDisableEnv"><mat-icon class="mat-icon-menu">list</mat-icon><a mat-button href="" *ngIf="IsCostMenu">Cost</a></li>
        <!-- <li *ngIf="menuDisableEnv"><mat-icon class="mat-icon-menu">list</mat-icon><a mat-button href="" *ngIf="IsCurrencyMenu">Currency</a></li> -->
        <li  *ngIf="isCurrencyVisble" class="nav-item" routerLinkActive="active" (mouseover)="CurrencyidemenuTrigger.openMenu()" (mouseout)="$event.stopPropagation();CurrencyidemenuTrigger.openMenu()">
          <mat-icon class="mat-icon-menu">account_balance</mat-icon>
          <a mat-button class="nav-link" #CurrencyidemenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Currencyside" *ngIf="IsCurrencyMenu">Currency  <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">play_arrow</mat-icon></a>
          <mat-menu #Currencyside="matMenu" [overlapTrigger]="false" xPosition="after" class="drop-spacer">
            <button mat-menu-item class="horzdropitem" (click)="openNewWindow('currency',$event)">Currency</button> 
          </mat-menu>
        </li>
        <li *ngIf="menuDisableEnv"><mat-icon class="mat-icon-menu">list</mat-icon><a mat-button href="" *ngIf="IsDeliveryMenu">Delivery</a></li>
      </ul>
</mat-sidenav>
 <mat-sidenav-content>
      <mat-toolbar color="primary">
        <button type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <a _ngcon class="br"></a>
        <div class="brand">
          <a href="#" >
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;width: 50px;" xml:space="preserve">
       <style type="text/css">
         .st0{fill:#FFFFFF;}
         .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
       </style>
       
       <g>
         <path class="st0" d="M16.25,42.81c0.41,1.75,1.53,2.63,3.36,2.63c0.9,0,1.61-0.2,2.12-0.6c0.52-0.4,0.78-0.97,0.78-1.71
           c0-0.64-0.23-1.12-0.68-1.44c-0.44-0.32-1.35-0.65-2.72-0.99c-2.37-0.6-3.99-1.34-4.88-2.23c-0.89-0.89-1.33-1.99-1.33-3.32
           c0-1.59,0.64-2.85,1.92-3.78c1.28-0.94,2.9-1.42,4.84-1.42c1.89,0,3.41,0.46,4.55,1.37c1.14,0.9,1.82,2.23,2.03,3.98h-3.83
           c-0.34-1.31-1.25-1.97-2.75-1.97c-0.83,0-1.5,0.16-2.02,0.47c-0.51,0.31-0.76,0.76-0.76,1.36c0,0.5,0.21,0.92,0.63,1.24
           c0.43,0.32,1.29,0.63,2.59,0.92c2.65,0.61,4.38,1.37,5.18,2.29c0.81,0.92,1.21,2.09,1.21,3.51c0,1.7-0.63,3.09-1.9,4.15
           c-1.27,1.07-2.92,1.6-4.97,1.6c-4.18,0-6.58-2.02-7.2-6.06H16.25z"/>
         <path class="st0" d="M28,48.49V30.31h5.61c3.14,0,5.53,0.79,7.19,2.38c1.65,1.58,2.48,3.82,2.48,6.72c0,2.91-0.83,5.15-2.49,6.73
           c-1.65,1.57-4.04,2.35-7.17,2.35H28z M31.95,33.72v11.36h1.66c3.79,0,5.68-1.89,5.68-5.68c0-3.79-1.89-5.68-5.68-5.68H31.95z"/>
         <path class="st0" d="M47.62,42.81c0.41,1.75,1.53,2.63,3.36,2.63c0.9,0,1.61-0.2,2.12-0.6c0.52-0.4,0.78-0.97,0.78-1.71
           c0-0.64-0.23-1.12-0.68-1.44c-0.44-0.32-1.35-0.65-2.72-0.99c-2.37-0.6-3.99-1.34-4.88-2.23c-0.89-0.89-1.33-1.99-1.33-3.32
           c0-1.59,0.64-2.85,1.92-3.78c1.28-0.94,2.9-1.42,4.84-1.42c1.89,0,3.41,0.46,4.55,1.37c1.14,0.9,1.82,2.23,2.03,3.98h-3.83
           c-0.34-1.31-1.25-1.97-2.75-1.97c-0.83,0-1.5,0.16-2.02,0.47c-0.51,0.31-0.76,0.76-0.76,1.36c0,0.5,0.21,0.92,0.63,1.24
           c0.43,0.32,1.29,0.63,2.59,0.92c2.65,0.61,4.38,1.37,5.18,2.29c0.81,0.92,1.21,2.09,1.21,3.51c0,1.7-0.63,3.09-1.9,4.15
           c-1.27,1.07-2.92,1.6-4.97,1.6c-4.18,0-6.58-2.02-7.2-6.06H47.62z"/>
         <path class="st1" d="M9.99,43.59c-0.32,0-0.6,0-0.81,0c-1.06,0-2.15-0.57-3.09-1.79c-0.94-1.22-1.61-2.95-1.77-4.82
           c-0.16-1.86,0.2-3.74,1.16-5.31c0.94-1.54,2.53-2.87,5.05-3.58l1.41-0.4l-0.13-1.43c-1.08-12.15,15.49-17.37,21.76-5.3l0.81,1.56
           l1.6-0.79c2.67-1.32,4.75-0.74,6.24,0.43c1.64,1.29,2.47,3.21,2.47,4.25v1.75h1.79c0.1,0,0.2,0,0.3,0.01
           c1.31-0.52,2.74-0.75,4.24-0.75c2.29,0,4.41,0.56,6.12,1.92c0.54,0.43,1.01,0.92,1.4,1.45c-0.31-0.69-0.71-1.37-1.22-2.01
           c-1.81-2.28-4.78-3.78-9.29-4.06c-0.51-1.88-1.79-3.88-3.57-5.28c-2.16-1.69-5.13-2.57-8.51-1.47c-8.3-13.12-27.88-7.38-27.78,7.22
           c-2.64,1.03-4.54,2.69-5.77,4.69c-1.41,2.3-1.87,4.94-1.66,7.39c0.21,2.45,1.08,4.83,2.48,6.63c1.4,1.81,3.45,3.18,5.96,3.18
           c0.45,0,1.15,0,2.04-0.01C10.62,46.03,10.22,44.85,9.99,43.59z"/>
       </g>
      </svg></a>
       <span style="text-transform: capitalize;font-size: 18px !important;width: 150px;">&nbsp;&nbsp;- {{getCurrentpage()}}</span> 
      </div>
       
      <div class="sidenavhorz" id="navhorz">
          <ul>
            <li class="nav-item">
              <a mat-button class="nav-link" #OrganizationmenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Organization" (mouseover)="OrganizationmenuTrigger.openMenu()" *ngIf="IsOrganizationMenu">Organization <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">arrow_drop_down</mat-icon></a>
              <mat-menu #Organization="matMenu" class="horzdrop cdk-overlay-pane-horz">
                <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('corporatebrand',$event,'Organization')">Corporate Brand</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('productbrand',$event,'Organization')">Product Brand</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('customergroup',$event,'Organization')">Customer Group</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('division',$event,'Organization')">Division</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('section',$event,'Organization')">Section</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('department',$event,'Organization')">Department</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('indexgroup',$event,'Organization')">Index Group</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('index',$event,'Organization')">Index</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="horzdropitem" (click)="openNewWindow('subindex',$event,'Organization')">Sub Index</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('garmentgroup',$event,'Organization')">Garment Group</button>
                <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('markupgroup',$event,'Organization')">Markup Group</button>
                <button mat-menu-item class="matitem" (click)="openNewWindow('earlierplanning',$event,'Organization')">Earlier Planning</button>
                <button mat-menu-item class="matitem" (click)="openNewWindow('southernhemisphere',$event,'Organization')">Southern Hemisphere</button>
                <!-- <button *ngIf="isOrgEnable" mat-menu-item class="matitem" (click)="openNewWindow('ab2department',$event,'Organization')">Assortment Build</button> -->
                <button mat-menu-item class="matitem" (click)="openExternalUrl()">
                  Data Hive   
                </button>
                <mat-divider></mat-divider>
                <mat-divider></mat-divider>
                <mat-divider></mat-divider>
              </mat-menu>
            </li>
            <li *ngIf="menuDisableEnv" class="nav-item">
              <a mat-button class="nav-link" #MarketmenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Market"  (mouseover)="MarketmenuTrigger.openMenu()"  *ngIf="IsMarketMenu">Market <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">arrow_drop_down</mat-icon></a>
              <mat-menu #Market="matMenu" class="horzdrop cdk-overlay-pane-horz">
                <button mat-menu-item class="horzdropitem" (click)="openNewWindow('channel',$event,'Market')">Channel</button>
                <mat-divider></mat-divider>
              </mat-menu>
            </li>
            <li *ngIf="menuDisableEnv" class="nav-item">
              <a mat-button class="nav-link" #CountrymenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Country" (mouseover)="CountrymenuTrigger.openMenu()" *ngIf="IsCountryMenu">Country <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">arrow_drop_down</mat-icon></a>
              <mat-menu #Country="matMenu" class="horzdrop cdk-overlay-pane-horz">
                <button mat-menu-item class="horzdropitem" (click)="openNewWindow('geographicalcountry',$event,'Country')">Geographical Country</button>
                <button mat-menu-item class="horzdropitem" (click)="openNewWindow('fiscalcountry',$event,'Country')">Fiscal Country</button>
                <button mat-menu-item class="horzdropitem" (click)="openNewWindow('hmcountry',$event,'Country')">H&M Country</button>
                <mat-divider></mat-divider>
              </mat-menu>
            </li>
            <!-- <li><a mat-button href="corporatebrand"  target="_blank">Country</a></li> -->
            <li *ngIf="menuDisableEnv"><a mat-button href="" *ngIf="IsCustomMenu">Custom</a></li>
            <li *ngIf="menuDisableEnv"><a mat-button href="" *ngIf="IsCostMenu">Cost</a></li>
            <!-- <li *ngIf="menuDisableEnv"><a mat-button href="" *ngIf="IsCurrencyMenu">Currency</a></li> -->
            <li *ngIf="isCurrencyVisble" class="nav-item">
              <a mat-button class="nav-link" #CurrencymenuTrigger="matMenuTrigger" [matMenuTriggerFor]="Currency" (mouseover)="CurrencymenuTrigger.openMenu()" *ngIf="IsCurrencyMenu">Currency <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">arrow_drop_down</mat-icon></a>
              <mat-menu #Currency="matMenu" class="horzdrop cdk-overlay-pane-horz">
                <button mat-menu-item class="horzdropitem" (click)="openNewWindow('currency',$event,'Currency')">Currency</button>
                <!-- <button mat-menu-item class="horzdropitem" (click)="openNewWindow('fiscalcountry',$event,'Country')">Fiscal Country</button> -->
                <mat-divider></mat-divider>
              </mat-menu>
            </li>
            <li *ngIf="menuDisableEnv"><a mat-button href="" *ngIf="IsDeliveryMenu">Delivery</a></li>
           </ul>
        </div>
       <div class="spacer"></div>
        <a mat-button href="" class="user">
          <span *ngIf="loggedInUser">{{loggedInUser.split('.')[0]}}
         </span>
        </a>
        <a mat-button class="notifications" href="">
          <mat-icon class="material-icons-menu" matBadge="10" matBadgePosition="above after" style="font-size: 145%;">notifications</mat-icon>
        </a>
        <a mat-button  class="feedback" href="">
          <mat-icon class="material-icons-menu" style="font-size: 145%;">feedback</mat-icon>

        </a>
      </mat-toolbar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
