import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, ICellRendererParams, GridOptions } from 'ag-grid-community';
import { HmcountryFormComponent } from '../hmcountry-form/hmcountry-form.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HmcountryService } from 'src/app/services/country/hmcountry/hmcountry.service'
import { ExcelService } from 'src/app/shared/services/excel.service'
import * as alasql from 'alasql';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hmcountry-list',
  templateUrl: './hmcountry-list.component.html',
  styleUrls: ['./hmcountry-list.component.css']
})

export class HmcountryListComponent {
  gridColumnApi: ColumnApi;
  gridLength: any;
  gridCount: any;
  status: any = "active";
  matDialogRefForForm: MatDialogRef<HmcountryFormComponent>
  public columnDefs: ColDef[] = [
    { field: 'hmCountryCodeAndName', headerName: 'HM Country Name', headerTooltip:'HM Country Name' },
    { field: 'hmCountryISOCode', headerName: 'ISO Code', headerTooltip:'ISO Code' },
    { field: 'fiscalCountry', headerName: 'Fiscal Country', headerTooltip:'Fiscal Country'},
    { field: 'logisticCountry', headerName: 'Logistic Country', headerTooltip:'Logistic Country' },
    { field: 'countryOfProduction', headerName: 'Country Of Production', headerTooltip:'Country Of Production' },
    { field: 'countryOfOrigin', headerName: 'Country Of Origin', headerTooltip:'Country Of Origin' },
    { field: 'countryOfDelivery', headerName: 'Country Of Delivery' , headerTooltip:'Country Of Delivery'},
    {
      field: 'Edit | View', filter: false,
      floatingFilter: false,
      cellStyle: { "text-decoration": "underline", "cursor": "pointer" },
      cellRenderer: BtnCellRenderer,
    },
  ];
  public gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationNumberFormatter: (params) => {
      return params.value.toLocaleString();
    },
    headerHeight: 45,
    getRowHeight: function (params) {
      return 22;
    },
    animateRows: true,
    rowClassRules: {
      'ActiveBg': (params) => {
        return params.data.active
      },
      'DraftBg': (params) => {
        return !params.data.active
      },
    },
  }
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
  };

  public rowData!: any[];
  private gridApi!: GridApi;

  constructor(private excelService: ExcelService, public dialog: MatDialog, private http: HttpClient, private hmcountryService: HmcountryService) {
    this.hmcountryService.resfreshCall.subscribe(event => {
      if (event) {
        this.onGridReady(null)
      }
    })
  }

  onFirstDataRendered(params) {
    params.api.paginationGoToPage(4);
  }

  onChange(count) {
    this.gridOptions.api.paginationSetPageSize(Number(count));
  }

  openCreate(){
    let msg = "createHit"
    this.openDialog(msg)
  }

  openDialog(msg) {
    this.matDialogRefForForm = this.dialog.open(HmcountryFormComponent, {
      data: { rowData: '', msg: msg },
      disableClose: false,
    });
    this.matDialogRefForForm?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        // this.params = ''
      }
    });
  }

  getpagesizeoptions(): number[] {
    if (this.gridLength > 25)
      return [10, 20, 25, this.gridLength];
    else if (this.gridLength > 20)
      return [10, 20, this.gridLength];
    else if (this.gridLength > 10)
      return [10, this.gridLength];
    else if (this.gridLength > 5)
    return [5, this.gridLength];
    return null;
  }

  onGridReady(params: GridReadyEvent<any>) {

    this.hmcountryService.getHmCountryList().pipe(map(d=>{
      if(d){
      d?.hmCountry?.forEach(data=>{
        data.fiscalCountry=data?.fiscalCountry==true?'YES':'NO';
        data.logisticCountry=data?.logisticCountry==true?'YES':'NO';
        data.countryOfProduction=data?.countryOfProduction==true?'YES':'NO';
        data.countryOfOrigin=data?.countryOfOrigin==true?'YES':'NO';
        data.countryOfDelivery=data?.countryOfDelivery==true?'YES':'NO';
      })
      return d
    }
    })).subscribe(data=>{
      if(data) {
        this.gridLength = data?.hmCountry?.length;
        this.rowData = data?.hmCountry;
        switch (this._searchform.value.status) {
          case "all":
            this.rowData = data?.hmCountry;
            break;
          case "active":
            this.rowData = this.rowData.filter(r => r.active === true)
            break;
          case "draft":
            this.rowData = this.rowData.filter(r => r.active === false)
            break;
        }
        this.gridApi = params?.api;
        this.gridCount = this.getpagesizeoptions();
      }
    })

  }
  onBtnExport() {
    var dataWithDepartment = JSON.stringify(this.rowData).replace(/null/g, '""');//Remove null Department   
    var rowDataWithDepartment = JSON.parse(dataWithDepartment); //convert back to array  
    // var res = alasql('SEARCH / AS @data \ hmCountryName / AS @hmCountryName \ RETURN(@data->lastUpdatedBy as LastUpdated) \ FROM ?', [rowDataWithDepartment])
    // this.excelService.exportAsExcelFile(res, 'ProductBrand');
  }

  _searchform: FormGroup = new FormGroup({
    status: new FormControl(),
  });
}

// Below interface is for handling button events inside grid
@Component({
  selector: 'btn-cell-renderer',
  template: `<a (click)="editClickedHandler()">Edit</a> | <a (click)="viewClickedHandler2()" >View</a>`,
})
export class BtnCellRenderer implements ICellRendererAngularComp {

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    throw new Error('Method not implemented.');
  }
  private params: any;
  matDialogRefForForm: MatDialogRef<HmcountryFormComponent>;
  constructor(public dialog: MatDialog) {
  }

  agInit(params: any): void {
    this.params = params;
  }
  openDialog(msg) {
    this.matDialogRefForForm = this.dialog.open(HmcountryFormComponent, {
      data: { rowData: this.params, msg: msg },
      disableClose: false,
    });
    this.matDialogRefForForm?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        this.params = ''
      }
    });
  }

  editClickedHandler() {
    let msg = "editHit"
    this.openDialog(msg)
  }
  viewClickedHandler2() {
    let msg = "viewHit"
    this.openDialog(msg)
  }
}
