import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, ICellRendererParams, GridOptions } from 'ag-grid-community';
import { Ab2departmentformComponent } from '../../ab2departmentform/ab2departmentform/ab2departmentform.component';
import { ab2displaydepartmentlist } from './ab2displaydepartmentlist'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Ab2departmentsService } from 'src/app/services/organization/ab2departments/ab2departments.service'
import { ExcelService } from 'src/app/shared/services/excel.service'
import * as alasql from 'alasql';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ab2departmentlist',
  templateUrl: './ab2departmentlist.component.html',
  styleUrls: ['./ab2departmentlist.component.css']
})

export class Ab2departmentlistComponent {
  matDialogRefForDepartment: MatDialogRef<ab2displaydepartmentlist>;
  matDialogRefForForm: MatDialogRef<Ab2departmentformComponent>;
  department: string = "";
  gridColumnApi: ColumnApi;
  downloadData=[];
  gridLength: any;
  gridCount: any;
  status: any = "active";
  public columnDefs: ColDef[] = [
    { field: 'corporateBrandCodeAndName', headerName: 'Corporate Brand' },
    {
      field: 'departments',
      cellStyle: { "text-decoration": "underline", "cursor": "pointer" },
      resizable: false,
      valueGetter: (row: any) => {
        let deplist: any = []
        if(row?.data?.departments !== null){
          row?.data?.departments?.forEach(element => {
            deplist.push(element?.departmentCodeAndName)
          });
        }else{
          deplist="Department is Ended"
        }
        
        return deplist;
      }
    },
    { field: 'seasonNumber', headerName: 'Season' },
    { field: 'lastUpdatedBy', headerName: 'Last Updated By' },
    { field: 'lastUpdatedTime', headerName: 'Last Updated On' },
    {
      field: 'Edit | View', filter: false,
      floatingFilter: false,
      cellStyle: { "text-decoration": "underline", "cursor": "pointer" },
      cellRenderer: BtnCellRenderer,
    },
  ];
  public gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    paginationNumberFormatter: (params) => {  
     this.getDownloadData()
      return params.value.toLocaleString();
    },
    headerHeight: 45,
    getRowHeight: function (params) {
      return 22;
    },
    animateRows: true,
    rowClassRules: {
      'ActiveBg': (params) => {
        return params.data.active 
      },
      'DraftBg': (params) => {
        return params.data.draft
      },
      'InactiveBg': (params) => {
        return !params.data.active && !params.data.draft
      },
    },
  }
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
  };

  public rowData!: any[];
  private gridApi!: GridApi;

  constructor(private excelService: ExcelService, public dialog: MatDialog, private http: HttpClient,
    private ab2departmentsService:Ab2departmentsService) {
    this.ab2departmentsService.resfreshCall.subscribe(event => {
      if (event) {
        this.onGridReady(null)
      }
    })
  }
 
  onFirstDataRendered(params) {
    params.api.paginationGoToPage(4);
  }

  getDownloadData(){
    if(this.gridOptions.api){
      let allRows = [];
      this.downloadData=[];
      this.gridOptions?.api?.forEachNodeAfterFilter((rowNode) => allRows?.push(rowNode));
        allRows?.forEach(ele=>{
          this.downloadData.push(ele?.data)
        })
    }
  }

  onChange(count) {
    this.gridOptions.api.paginationSetPageSize(Number(count));
  }

  getpagesizeoptions(): number[] {
    if (this.gridLength > 25)
      return [10, 20, 25, this.gridLength];
    else if (this.gridLength > 20)
      return [10, 20, this.gridLength];
    else if (this.gridLength > 10)
      return [10, this.gridLength];
    else if (this.gridLength > 5)
    return [5, this.gridLength];
    return null;
  }



  onGridReady(params: GridReadyEvent<any>) {
    this.ab2departmentsService.getAllAb2DepartmentsList().pipe(map(ele=>{
      ele?.getABDepartmentAll.map(ele => {
        ele.lastUpdatedTime = ele?.lastUpdatedTime?.substring(0,10)
      })
      return ele
    })).subscribe(data => {
      if (data) {
        this.gridLength = data?.getABDepartmentAll.length;
        this.rowData = data?.getABDepartmentAll;
        switch (this._searchform.value.status) {
          case "all":
            this.rowData = data?.getABDepartmentAll;
            break;
          case "active":
            this.rowData = this.rowData.filter(r => r.active === true)
            break;
          case "draft":
            this.rowData = this.rowData.filter(r => r.draft === true)
            break;
          case "inactive":
            this.rowData = this.rowData.filter(r => r.active === false && r.draft === false)
            break;
        }
        this.gridApi = params?.api;
        this.gridCount = this.getpagesizeoptions();
      }
    })
  }
  onBtnExport() {
    const uniqueArray = this.downloadData?.filter((value, index) => {
      const _value = JSON.stringify(value);
      return index === this.downloadData?.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    });
    var dataWithDepartment = JSON.stringify(uniqueArray)?.replace(/null/g, '""');//Remove null Department   
    var rowDataWithDepartment = JSON.parse(dataWithDepartment); //convert back to array  
    // var res = alasql('SEARCH / AS @data \ departments / AS @departments \ RETURN(@data->abDepartmentId as AB2DepartmentNumber, @data->corporateBrandCodeAndName as CorporateBrand, @departments->divisionCodeAndName as Division, @departments->sectionCodeAndName as Section, @departments->departmentCodeAndName as Departments, @data->seasonNumber as seasonNumber , @data->lastUpdatedBy as LastUpdated) \ FROM ?', [rowDataWithDepartment])
    // this.excelService.exportAsExcelFile(res, 'Assortment Build');
  }

  openCreate(){
    let msg = "createHit"
    this.openDialog(msg)
  }

  openDialog(msg) {
    this.matDialogRefForForm = this.dialog.open(Ab2departmentformComponent, {
      data: { rowData: '', msg: msg },
      autoFocus: false,
      disableClose: false,
    });
    this.matDialogRefForForm?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        // this.params = ''
      }
    });
  }

  onCellClicked(params: any) {
    if (params?.colDef?.field === 'departments') {
      this.openDepartmentPopUp(params)
    }
  }

  openDepartmentPopUp(data: any) {
    this.matDialogRefForDepartment = this.dialog.open(ab2displaydepartmentlist, {
      data: { department: data?.data?.departments },
      disableClose: false
    });

    this.matDialogRefForDepartment.afterClosed().subscribe(res => {
      if ((res == true)) {
        this.department = "";
      }
    });
  }
  //status  Search Form
  _searchform: FormGroup = new FormGroup({
    status: new FormControl(),
  });
}

// Below interface is for handling button events inside grid
@Component({
  selector: 'btn-cell-renderer',
  template: `<a (click)="editClickedHandler()">Edit</a> | <a (click)="viewClickedHandler2()" >View</a>`,
})
export class BtnCellRenderer implements ICellRendererAngularComp {

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    throw new Error('Method not implemented.');
  }
  private params: any;
  matDialogRefForForm: MatDialogRef<Ab2departmentformComponent>;
  constructor(public dialog: MatDialog) {
  }

  agInit(params: any): void {
    this.params = params;
  }
  openDialog(msg) {
    this.matDialogRefForForm = this.dialog.open(Ab2departmentformComponent, {
      data: { rowData: this.params, msg: msg },
      autoFocus:false,
      disableClose: false,
    });
    this.matDialogRefForForm?.afterClosed()?.subscribe(res => {
      if ((res == true)) {
        this.params = ''
      }
    });
  }

  editClickedHandler() {
    let msg = "editHit"
    this.openDialog(msg)
  }
  viewClickedHandler2() {
    let msg = "viewHit"
    this.openDialog(msg)
  }
}
