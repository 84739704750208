import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ApplicationConfig } from './ApplicationConfig';
import { Observable } from 'rxjs';
import { IApplicationConfig } from './IApplicationConfig';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private http: HttpClient;
  RunEnvironment:any;
  constructor(private readonly httpClient: HttpBackend, public _ApplicationConfig: ApplicationConfig) {
  this.http = new HttpClient(httpClient);
  }

  loadConfigurationSettings() {
    console.log("Inside loadConfigurationSettings : ");
    //this._ApplicationConfig.APP_Environment = "dev";
    //this._ApplicationConfig.APP_ClientID = "ed643a12-b9c0-463c-b29f-e26f573cd450";
    //this._ApplicationConfig.AppInsightsKey="7d44af3c-4110-4f9c-a36a-48d458a0e597";
    //this._ApplicationConfig.App_DataHiveUrl ="https://sit.datahive.hmgroup.tech/";
    //localStorage.setItem("RunEnvironment", "dev");
    //this.RunEnvironment = "dev";
      return new Promise((resolve, reject) => {
         this.GetApplicationConfig().subscribe((data: IApplicationConfig) => {
           console.log("Inside data.APP_Environment : " + data.APP_Environment);
           this.RunEnvironment = data.APP_Environment;
           this._ApplicationConfig.APP_Environment = data.APP_Environment;
           this._ApplicationConfig.APP_ClientID = data.APP_ClientID;
           this._ApplicationConfig.AppInsightsKey = data.AppInsightsKey;
           this._ApplicationConfig.APP_IsOrgEnable = data.APP_IsOrgEnable;
           this._ApplicationConfig.App_DataHiveUrl = data.App_DataHiveUrl;
           localStorage.setItem("RunEnvironment", data.APP_Environment);
           localStorage.setItem('clientId',data.APP_ClientID);
           localStorage.setItem('AppInsightsKey',data.AppInsightsKey); 
           console.log("Inseide RunEnvironment : " + data.APP_Environment);
           resolve(true);
         });
      });
  }

  GetApplicationConfig(): Observable<IApplicationConfig> {
    return this.http.get<IApplicationConfig>('apibackend/ApplicationConfiguration')
  }

}
