import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from '../../../../shared/services/account.service'
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { UserInfo } from 'src/app/models/UserInfo';
import { ConfigurationService } from '../../../../shared/services/configuration.service';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { UserAccess } from "src/app/models/userAccess"
import { ModulePermission } from 'src/app/models/enum';


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  providers: [AccountService]

})

export class MainNavComponent {
  public loggedInUser: any;
  public userInfo: UserInfo;
  public isloggedIn: boolean = false;
  public userRole: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  IsOrganizationMenu: any = false;
  IsMarketMenu: any = false;
  IsCountryMenu: any = false;
  IsCustomMenu: any = false;
  IsCostMenu: any = false;
  IsCurrencyMenu: any = false;
  IsDeliveryMenu: any = false;

  constructor(private breakpointObserver: BreakpointObserver,
    public _commonutilityService: CommonUtilityService,
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private account: AccountService,
    public _configurationService: ConfigurationService,
    public _userAccess: UserAccess) {
    this.fnismenuEnable();
  }
  public RunEnvironment = this._configurationService.RunEnvironment;
  public isOrgEnable = this._configurationService._ApplicationConfig.APP_IsOrgEnable == 'False' ? false : true;
  public menuDisable = this.RunEnvironment == 'prod' ? false : true;//only for enabling production menu
  public menuDisableEnv = this.RunEnvironment == 'test' ? false : true;//only for enabling test menu
  public menuDisableDevEnv = this.RunEnvironment == 'dev' ? true : false;//only for enabling dev menu
  public dataHiveUrl = this.RunEnvironment === 'prod'
? 'https://datahive.hmgroup.tech/'
    : this.RunEnvironment === 'test'
? 'https://sit.datahive.hmgroup.tech/'
    : this.RunEnvironment === 'dev'
? 'https://dev.datahive.hmgroup.tech/'
: 'https://dev.datahive.hmgroup.tech/';
  
  public isCurrencyVisble = false;
  public ismenuEnable: any = [];
  currentpage: any;

  openNewWindow(urlname: any, event: any) {
    this.currentpage = urlname;
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${urlname}/`])
    );
    urlname = "";
    if (event.ctrlKey) {
      this.router.navigate([]).then(result => { window.open(url, '_blank'); });
    }
    else {
      this.router.navigate([url]);
    }
  }
  openExternalUrl() {
    window.open(this.dataHiveUrl, '_blank'); // Opens the URL in a new tab
  }
  getCurrentpage() {
    switch (this.currentpage) {
      case undefined:
        this.currentpage = "Home";
        break;
      case "corporatebrand":
        this.currentpage = 'Corporate Brand';
        break;
      case "productbrand":
        this.currentpage = 'Product Brand';
        break;
      case "customergroup":
        this.currentpage = 'Customer Group';
        break;
      case "indexgroup":
        this.currentpage = 'Index Group';
        break;
      case "subindex":
        this.currentpage = 'Sub Index';
        break;
      case "garmentgroup":
        this.currentpage = 'Garment Group';
        break;
      case "markupgroup":
        this.currentpage = 'Markup Group';
        break;
      case "earlierplanning":
        this.currentpage = 'Earlier Planning';
        break;
      case "southernhemisphere":
        this.currentpage = 'Southern Hemisphere';
        break;
      case "geographicalcountry":
          this.currentpage = 'Geographical Country';
          break;
      case "fiscalcountry":
          this.currentpage = 'Fiscal Country';
          break;
      case "hmcountry":
          this.currentpage = 'H&M Country';
          break;
      case "ab2department":
          this.currentpage = 'Assortment Build';
          break;
          case "datahive":
          this.currentpage = 'Data Hive';
          break;
    }
    return this.currentpage;
  }

  public fnismenuEnable() {
    for (let i = 0; i < this._userAccess.ismenuEnable.length; i++) {
      switch (this._userAccess.ismenuEnable[i]) {
        case ModulePermission.Organization_Read:
        case ModulePermission.Organization_Write:
          this.IsOrganizationMenu = true;
          break;
        case ModulePermission.Market_Read:
        case ModulePermission.Market_Write:
          this.IsMarketMenu = true;
          break;
        case ModulePermission.Country_Read:
        case ModulePermission.Country_Write:
          this.IsCountryMenu = true;
          break;
        case ModulePermission.Custom_Read:
        case ModulePermission.Custom_Write:
          this.IsCustomMenu = true;
          break;
        case ModulePermission.Cost_Read:
        case ModulePermission.Cost_Write:
          this.IsCostMenu = true;
          break;
        case ModulePermission.Currency_Read:
        case ModulePermission.Currency_Write:
          this.IsCurrencyMenu = true;
          break;
        case ModulePermission.Delivery_Read:
        case ModulePermission.Delivery_Write:
          this.IsDeliveryMenu = true;
          break;
      }
    }
  }

  ngOnInit() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        let userInfo: any;
        userInfo = JSON.stringify(result.payload)
        userInfo = JSON.parse(userInfo).idTokenClaims;
        this.userInfo = { Name: userInfo.name, Email: userInfo.preferred_username, Role: userInfo.roles[0], isLoggedIn: true }
        localStorage.setItem("HostName", window.location.hostname)
        localStorage.setItem("UserInfo", JSON.stringify(this.userInfo))
        this.account.getUserInfo();
        this.loggedInUser = this.userInfo.Email;
        this.isloggedIn = true;
      });
    if (localStorage.getItem("UserInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("UserInfo"));
      this.loggedInUser = this.userInfo.Email;
      this.isloggedIn = this.userInfo.isLoggedIn;
      let list = this.userInfo.Role;
      let rolelist = list.split(",");
      this.userRole = rolelist.length <= 1 ? rolelist[0] : '';
      this.userRole = this.userRole.substring(4);
      this.account.userInfo = this.userInfo;
    }
  }

}


