import { Component, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "DepartmentviewComponent",
  template: `
  <mat-dialog-content class="mat-typography">
  <h2 mat-dialog-title style="margin: 0px 0px 0px 10px !important;">Department</h2>
  <div id="Customercustomergroup">
  <div class="mat-table-container">
    <table class="full-width-table">
      <thead>
        <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
          <th style="padding:10px 10px 10px 10px;">Department</th>
          <th style="padding:10px 10px 10px 10px;">Start Season</th>
          <th style="padding:10px 10px 10px 10px;">End Season</th>
          <th style="padding:10px 10px 10px 10px;">Corporate Brand</th>
          <th style="padding:10px 10px 10px 10px;">Section</th>
          <th style="padding:10px 10px 10px 10px;">Garment Group</th>
          <th style="padding:10px 10px 10px 10px;">Division</th>
          </tr>
      </thead>
      <tbody class="mat-grid-tile scroller" Id="tblscroll">
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
          {{rowData.departmentCode}} {{rowData.departmentName}}
          </td>
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
          {{rowData.startSeason | seasonDate}}
          </td>
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
          {{(rowData.endSeason !=null && rowData.endSeason!='' && rowData.endSeason.includes("99")) ? '':rowData.endSeason| seasonDate}}
          </td>
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
          {{rowData.corporateBrandName}}
          </td>
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
          {{rowData.sectionCodeName}}
          </td>
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
          {{rowData.garmentGroupCodeName}}
          </td>
          <td style="padding:10px 10px 10px 10px;border-bottom: 0.5px solid #dfdfdfdf;">
         {{rowData.divisionCodeName}}
          </td>
         <tbody>
    </table>
  </div>
</div>
<br>
 <mat-checkbox [disableRipple]="true"
 (click)="$event.preventDefault()" 
 id="inpglobalinshopweek" [checked]="rowData.globalInShopWeek" class="example-margin width-20" color='primary'>Global ISW </mat-checkbox>
 <mat-checkbox [disableRipple]="true"
 (click)="$event.preventDefault()"   
 id="inpactive" [checked]="rowData.active" class="example-margin width-20 ml-2" color='primary'>Active</mat-checkbox>
 <mat-checkbox [disableRipple]="true"
 (click)="$event.preventDefault()" id="inpdraft" [checked]="rowData.draft"
 class="example-margin width-12  ml-2" color='primary'>Draft</mat-checkbox>
<br>
<div style="display:flex;margin-top:10px;">
<div *ngIf='rowData.globalInShopWeek' style="width:370px">
    <h2 mat-dialog-title style="margin: 0px 0px 0px 10px !important;">Global ISW</h2>
    <div id="globalIsw">
      <div class="mat-table-container">
        <table class="full-width-table">
          <thead>
              <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
                  <th style="padding:10px 20px 10px 20px">From Season</th>
                  <th style="padding:10px 20px 10px 20px">To Season</th>
              </tr>
          </thead>
          <tbody class="mat-grid-tile scroller" Id="tblscroll">
              <tr >
                  <td style="padding:10px 20px 10px 20px; border-bottom: 0.5px solid #dfdfdfdf;">
                      {{rowData.globalISWFromSeasonNumber | seasonDate}}
                  </td>
                  <td style="padding:10px 20px 10px 20px; border-bottom: 0.5px solid #dfdfdfdf;">
                       {{rowData.globalISWToSeasonNumber | seasonDate}}
                  </td>
              </tr>
            <tr *ngIf="depSubIndexList?.length === 0">
              <td style="border-bottom: 1px solid #dfdfdfdf;text-align: center; ">No records found</td>
            </tr>
          <tbody>
        </table>
      </div>
    </div>
</div>

<div style="width:370px">
    <h2 mat-dialog-title style="margin: 0px 0px 0px 10px !important;">SubIndex</h2>
    <div id="SubIndex">
      <div class="mat-table-container">
        <table class="full-width-table">
          <thead>
              <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
                  <th style="padding:10px 20px 10px 20px">Start Season</th>
                  <th style="padding:10px 20px 10px 20px">End Season</th>
                  <th style="padding:10px 20px 10px 20px">SubIndex</th>
              </tr>
          </thead>
          <tbody class="mat-grid-tile scroller" Id="tblscroll">
              <tr *ngFor="let itemsub of depSubIndexList">
                  <td style="padding:10px 20px 10px 20px; border-bottom: 0.5px solid #dfdfdfdf;">
                      {{itemsub.startSeason | seasonDate}}
                  </td>
                  <td style="padding:10px 20px 10px 20px; border-bottom: 0.5px solid #dfdfdfdf;">
                      {{(itemsub.endSeason !=null && itemsub.endSeason!='' && itemsub.endSeason.includes("99")) ? '':itemsub.endSeason| seasonDate}}
                  </td>
                  <td style="padding:10px 20px 10px 20px; border-bottom: 0.5px solid #dfdfdfdf;">
                      {{itemsub.subIndexCode}}
                  </td>
              </tr>
            <tr *ngIf="depSubIndexList?.length === 0">
              <td style="border-bottom: 1px solid #dfdfdfdf;text-align: center; ">No records found</td>
            </tr>
          <tbody>
        </table>
      </div>
    </div>
</div>


<div style="width:238px">
    <h2 mat-dialog-title style="margin: 0px 0px 0px 10px !important;min-width: 180px">Custom Customer Group</h2>
    <div id="Customercustomergroup">
      <div class="mat-table-container">
        <table class="full-width-table">
          <thead>
            <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
              <th style="padding:10px 20px 10px 20px;">Custom Customer Group</th>
            </tr>
          </thead>
          <tbody class="mat-grid-tile scroller" Id="tblscroll">
            <tr *ngFor="let item of depCustomCustomerGroupList">
              <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">
                {{item.customCustomerGroupName}}
              </td>
            </tr>
            <tr *ngIf="depCustomCustomerGroupList?.length === 0">
              <td style="border-bottom: 1px solid #dfdfdfdf; text-align: center; ">No records found</td>
            </tr>
          <tbody>
        </table>
      </div>
    </div>
</div>
<div>
<h2 mat-dialog-title style="margin: 0px 0px 0px 10px !important;">Markupgroup</h2>
    <div id="Markupgroup">
      <div class="mat-table-container">
        <table class="full-width-table">
          <thead>
              <tr style="color:#03538B  ;background-color:  #dfdfdfdf !important;text-align: left;">
                  <th style="padding:10px 20px 10px 20px;">Start Season</th>
                  <th style="padding:10px 20px 10px 20px;">End Season</th>
                  <th style="padding:10px 20px 10px 20px;">Markupgroup</th>
              </tr>
          </thead>
          <tbody class="mat-grid-tile scroller" Id="tblscroll">
              <tr *ngFor="let itemmark of depMarkupGroupList">
                  <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">
                      {{itemmark.startSeason | seasonDate}}
                  </td>
                  <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">
                     {{(itemmark.endSeason !=null && itemmark.endSeason!='' && itemmark.endSeason.includes("99")) ? '':itemmark.endSeason| seasonDate}}
                  </td>
                  <td style="padding:10px 20px 10px 20px;border-bottom: 0.5px solid #dfdfdfdf;">
                      {{itemmark.markupGroupCode+'-'+itemmark.markUpGroupName}}
                  </td>
              </tr>
            <tr *ngIf="depMarkupGroupList?.length === 0">
           
              <td style="border-bottom: 1px solid #dfdfdfdf; text-align: center; ">No records found</td>
              
            </tr>
          <tbody>
        </table>
      </div>
    </div>
    </div>
 </div>
 </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
   </mat-dialog-actions>
  `
})
export class DepartmentviewComponent {
  depSubIndexList: any[] = [];
  rowData: any;
  depCustomCustomerGroupList: any[] = [];
  depMarkupGroupList: any[] = [];
  department: any;
  constructor(
    private _mdr: MatDialogRef<DepartmentviewComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.depSubIndexList = data.department.subIndex.sort((a,b) => (a.startSeason > b.startSeason ? 1 : -1));
    this.depCustomCustomerGroupList = data.department.customCustomerGroup;
    this.depMarkupGroupList = data.department.markUpGroup;
    this.rowData = data.rowData;
  }
  CloseDialog() {
    this._mdr.close(false)
  }
}