import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ApplicationConfig {

  public APP_Environment = "";
  public APP_ClientID = "";
  public APP_IsOrgEnable = "";
  public AppInsightsKey = "";
  public App_DataHiveUrl = "";
}
